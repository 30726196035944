/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import classess from "./style.module.scss";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getArtistById,
  getReports,
  setIsLoading,
  setIsLoadingNew,
  setIsLoadingEstEarning,
  setLicenceType,
  setNewMusicTracks,
  setSelectedTrackCount,
  setSelectedTracks,
  setTotalFunding,
  setTotalTracks,
  setTracks,
} from "../../redux/slice/artist";
import { closeModal } from "../../redux/slice/modal";
import AddTracks from "../../components/add-track/add-track";
import EditTrackModel from "../../components/edit-track-popup/edit-track-popup";
import TrackChart from "../../components/track-chart/track-chart";
import axios from "axios";
import { config as URLconfig } from "../../enviorment/enviorment";
import VerifyConfig from "../../components/view-funding-dashboard-items/verify/config/verify-config";
import SendConfig from "../../components/view-funding-dashboard-items/send/config/send-config";
import ConfirmConfig from "../../components/view-funding-dashboard-items/confirm/config/confirm-config";
import CustomizedConfig from "../../components/view-funding-dashboard-items/customize/config/customize-config";
import InitiateContract from "../../components/view-funding-dashboard-items/initiate_contract/initiate-contract";
import Grid from "@mui/material/Grid";
import usePDF from "../../hooks/usePdf";
import { toast } from "react-toastify";
import { getNewMusicByID } from "../../redux/slice/new-music";
import NewFundingDetails from "../../components/view-funding-dashboard-items/new-funding-details/new-funding-details";
import DealSummary from "../../components/view-funding-dashboard-items/new-funding-details/deal-summary/DealSummary";
import { Tabs, Tooltip } from "@mui/material";
import Lincense from "../../components/view-funding-dashboard-items/lincense/Lincense";
import BuyOut from "../../components/view-funding-dashboard-items/buy-out/BuyOut";
import { getItemToLocalStorage } from "../../services/storage";
import AuthEnum from "../../enums/auth.enum";
import { RiLock2Line } from "react-icons/ri";

const ViewFundingDashboard = () => {
  const dispatch = useDispatch();
  const dispatchRef = useRef(dispatch);
  const { id } = useParams();
  const pdf = usePDF();
  const selected_tracks_ids = useSelector(
    (state) => state.artist.selectedTracks
  );
  const user = useSelector((state) => state.auth.user);
  const artist = useSelector((state) => state.artist.artist);
  const totalFunding = useSelector((state) => state.artist.totalFunding);
  const newMusicData = useSelector((state) => state.new_music.newMusic);
  const [masterEst, setMasterEst] = useState([]);
  const [publishEst, setPublishEst] = useState([]);
  const isLoading = useSelector((state) => state.artist.isLoading);
  const selected = useSelector((state) => state.artist.selectedTracks);
  const totalTracks = useSelector((state) => state.artist.totalTracks);
  const modalName = useSelector((state) => state.modal.name);
  const [included_music, set_included_music] = useState(null);
  const [contract_length, set_contract_length] = useState(null);
  const [catelog_income, set_catelog_income] = useState(null);
  const [new_music_income, set_new_music_income] = useState(null);
  const [artist_advance, set_artist_advance] = useState(null);
  const [marketing_budget, set_marketing_budget] = useState(null);
  const newMusicTracks = useSelector((state) => state.artist.newMusicTracks);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(1);
  const [set_customize_funding] = useState(0);
  const [new_music_estimiate, setNewMusicEst] = useState(0);
  const [funding_metadata, set_funding_metadata] = useState(null);
  const tracks = useSelector((state) => state.artist.tracks);
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const [monthlyIncome, setMonthlyIncome] = useState(0);
  const licenseType = useSelector((state) => state.artist.licenseType);
  const multiple = useSelector((state) => state.artist.multiple);
  const [RECOUPMENTPERIOD, setRECOUPMENTPERIOD] = useState(0);
  const [RECOUPMENTPERIOD_IN_ENG, setRECOUPMENTPERIOD_IN_ENG] = useState("");
  const [SingleCount, setSingleCount] = useState(0);
  const [EpCount, setEpCount] = useState(0);
  const [AlbumCount, setAlbumCount] = useState(0);
  const [EstimatedYearlyEarnings, setEstimatedYearlyEarnings] = useState(0);
  const [CountryWiseEst, setCountryWiseEst] = useState(0);
  const [artAdvance, setArtAdvance] = useState("");
  const [marketBudget, setMarketBudget] = useState("");
  const [changeButton, setChangeButton] = useState("");
  const [showContract, setShowContract] = useState(false);
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [containerExpanded, setContainerExpanded] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [tracksLoad, setTracksLoad] = useState(false);

  const handleChange = (event, newValue) => {
    if (value === 2) {
      dispatch(setLicenceType("license"));
      // console.log("licenseType");
    }
    if (value === 1) {
      dispatch(setLicenceType("buyout"));
      // console.log("buyout");
    }
    setContainerExpanded([false, false, false, false]);
    setValue(newValue);
  };
  const buttonStyle = {
    backgroundColor: changeButton ? "#ff3d81" : "#5a7380",
  };
  const d = new Date();
  d.setDate(d.getDate() - 1);

  const handleCloseModal = () => {
    dispatch(closeModal({ name: "track-chart", data: "" }));
  };

  window.addEventListener("popstate", () => {
    handleCloseModal();
  });

  useEffect(() => {
    if (id) {
      dispatch(
        getArtistById({
          id,
        })
      );
    }
  }, [id]);

  useEffect(() => {
    dispatch(setLicenceType("license"));
    dispatch(setTotalFunding(0));
    dispatch(setTotalTracks(0));
    dispatch(setTracks([]));
  }, []);

  useEffect(() => {
    dispatchRef.current(setTotalFunding(0));
    dispatchRef.current(setTotalTracks(0));
    dispatchRef.current(setTracks([]));
  }, [dispatchRef]);

  let fetchNewMusic = (artist_id) => {
    dispatch(
      getNewMusicByID({
        id: artist_id,
      })
    );
  };

  useEffect(() => {
    if (newMusicData) {
      const epCount = newMusicData.filter((item) => item.music === "ep").length;
      const singleCount = newMusicData.filter(
        (item) => item.music === "single"
      ).length;
      const albumeCount = newMusicData.filter(
        (item) => item.music === "album"
      ).length;
      setSingleCount(singleCount);
      setEpCount(epCount);
      setAlbumCount(albumeCount);
    }
  }, [newMusicData]);

  useEffect(() => {
    if (artist?.spotify_id !== undefined) {
      setTracksLoad(true);
      fetchNewMusic(artist.spotify_id);
      const fetchTracks = async (spotify_id) => {
        axios
          .get(`${URLconfig.BASE_URL}/artist-tracks/${spotify_id}`)
          .then(async (res) => {
            const artistTracks = await res.data;
            if (artistTracks?.data.length === 0) {
              dispatch(setIsLoading(false));
              dispatch(setIsLoadingNew(false));
              dispatch(setIsLoadingEstEarning(false));
            } else {
              let selected_tracks = artistTracks?.data
                .filter((track) => track.is_selected === 1)
                .map((el) => el._id);

              let new_music_selected_tracks = artistTracks?.data
                .filter((track) => track.is_selected === 2)
                .map((el) => el._id);
              dispatch(setSelectedTrackCount(selected_tracks.length));
              dispatch(setSelectedTracks(selected_tracks));
              dispatch(setNewMusicTracks(new_music_selected_tracks));
              dispatch(setTracks([...artistTracks.data.map(mapTracks)]));
              dispatch(setTotalTracks(artistTracks.data.length));
              setTracksLoad(false);
            }
          })
          .catch((error) => {
            console.log("Error Of GetTracks " + error);
          });
      };

      dispatch(getReports(artist.spotify_id));
      fetchTracks(artist?.spotify_id);
    }
  }, [artist]);

  useEffect(() => {
    if (artist?.spotify_id !== undefined) {
      const getArtistFundingMetaData = async () => {
        if (artist?.spotify_id !== undefined) {
          let endpoint = `${URLconfig.BASE_URL}/artist-funding/${artist?.spotify_id}`;
          const artist_funding_response = await axios.get(endpoint);
          const artist_funding_data = artist_funding_response.data["data"];
          set_funding_metadata(artist_funding_data);
          set_contract_length(artist_funding_data.contract_length);
          set_new_music_income(artist_funding_data.new_music_income);
          set_catelog_income(artist_funding_data.catelog_income);
          set_artist_advance(artist_funding_data?.artist_advance);
          set_marketing_budget(artist_funding_data?.marketing_budget);
        }
      };
      dispatchRef.current(setIsLoading(true));
      dispatchRef.current(setIsLoadingNew(true));
      dispatch(setIsLoadingEstEarning(true));
      getArtistFundingMetaData();
    }
  }, [artist, dispatchRef]);

  const handleOpen = () => setOpen(true);

  const handleClose = (res) => {
    setOpen(false);
    if (res) {
      dispatch(
        getArtistById({
          id,
        })
      );
    }
  };

  const onChangeHandler = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsLoadingNew(true));
    dispatch(setIsLoadingEstEarning(true));
    const selected_tracks =
      selected.length > 0
        ? selected.map((e) => getSingleTrack(e))
        : tracks
            .filter((track) => track.is_selected === 1)
            .map((checkedtracks) => checkedtracks);
    const new_music = newMusicTracks.map((e) => getSingleTrack(e));
    const val = {
      included_music,
      contract_length,
      catelog_income,
      new_music_income,
      selected_tracks: selected_tracks.length > 0 ? selected_tracks : tracks,
      new_music_tracks: new_music,
    };

    calcalute_tracks_estimate(val);
  };

  function getSingleTrack(id) {
    return tracks.filter((track) => track.id === id)[0];
  }

  const calcalute_tracks_estimate = async (data) => {
    if (funding_metadata) {
      try {
        // const controller = new AbortController();
        // let options = {
        //   signal: controller.signal,
        // };
        let options = {
          headers: {
            authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
          },
        };

        const response = await axios.post(
          `${URLconfig.BASE_URL}/artist-funding/${artist?.spotify_id}/customize`,
          data,
          options
        );
        let x = 1;
        if (data?.multiple) {
          x =
            isNaN(parseInt(multiple)) === true ? multiple : parseInt(multiple);
        }

        let funding =
          licenseType === "license"
            ? response?.data?.data?.funding
            : parseInt(response?.data?.data?.funding) * x;
        dispatch(setTotalFunding(funding));
        let new_music_income =
          licenseType === "license"
            ? response?.data?.data?.new_music_income
            : 0;
        setNewMusicEst(new_music_income);
        setMasterEst(response?.data?.data?.master_estimated_revenue);
        setPublishEst(response?.data?.data?.Publishing_estimated_revenue);
        const earningdata = response?.data?.data?.dataWithEarnings;
        const sortedData = earningdata.sort((a, b) => b.earnings - a.earnings);
        setCountryWiseEst(sortedData);
        dispatch(setIsLoading(false));
        dispatch(setIsLoadingNew(false));
        // controller.abort();
      } catch (error) {
        console.log("Error Of Customize Funding " + error);
      }
    }
  };

  const calcalute_Year_income_by_tracks = async (data) => {
    try {
      dispatch(setIsLoadingEstEarning(true));
      const controller = new AbortController();
      const response = await axios.post(
        `${URLconfig.BASE_URL}/artist-funding/${artist?.spotify_id}/MonthlyCalculation`,
        data
      );
      const startDate = "2022-11";
      const endDate = "2023-10";
      const totalIncomeInRange = sumIncomeWithinRange(
        response.data.data,
        startDate,
        endDate
      );
      setMonthlyIncome(response.data.data);
      setEstimatedYearlyEarnings(totalIncomeInRange);
      dispatch(setIsLoadingEstEarning(false));
      controller.abort();
    } catch (error) {
      console.log("Error Of Customize Funding " + error);
    }
  };

  function sumIncomeWithinRange(data, startDate, endDate) {
    let totalIncome = 0;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentMonthYear = `${currentYear}-${currentMonth}`;
    const incomedata = Object.values(data);
    const dataArray = incomedata.filter(
      (entry) => entry.date !== currentMonthYear
    );
    const oneYearAgoDate = new Date(currentDate);
    oneYearAgoDate.setFullYear(oneYearAgoDate.getFullYear() - 1);
    const lastYearDate = new Date(currentDate);
    lastYearDate.setFullYear(currentDate.getFullYear() - 1);
    const lastYear = lastYearDate.getFullYear();
    const lastMonth = lastYearDate.getMonth() + 1;
    const formattedLastMonth = `${lastYear}-${lastMonth
      .toString()
      .padStart(2, "0")}`;
    let startDatess = formattedLastMonth;
    const endDatess = "2023-1";
    const endDatessss = "2022-12";
    const filteredData = dataArray.filter((item) => item.date >= endDatess);
    const filteredDatatwo = dataArray.filter(
      (item) => item.date >= startDatess && item.date <= endDatessss
    );
    const totalIncome1 = filteredData.reduce(
      (sum, item) => sum + item.income,
      0
    );
    const totalIncome2 = filteredDatatwo.reduce(
      (sum, item) => sum + item.income,
      0
    );
    totalIncome = totalIncome1 + totalIncome2;
    return totalIncome;
  }

  const calcalute_customize_funding_estimate = null;

  const downloadPDF = async () => {
    const year = contract_length === 1 ? "Year" : "Years";
    const length_of_contract = `${contract_length} ${year}`;
    const artist_name = artist.name;
    const catalog_income_artist_keep = `${catelog_income}%`;
    const prefix = "$";
    let total_master_est = 0;
    for (var i = 0; i < masterEst.length; i++) {
      total_master_est += Math.round(parseFloat(masterEst[i]).toFixed(2));
    }
    const masters_estimiate_revenue =
      prefix + internationalNumberFormat.format(total_master_est);
    let newmusic_estimiate_revenue =
      new_music_estimiate < 0
        ? prefix + "0"
        : prefix + internationalNumberFormat.format(new_music_estimiate);
    const funding_estimiate_revenue =
      prefix +
      internationalNumberFormat.format(totalFunding + new_music_estimiate);
    const shares_purchases = "30%";
    let selected_tracks =
      selected_tracks_ids.length > 0
        ? selected_tracks_ids.map((e) => getSingleTrack(e))
        : tracks;
    let trows = [];
    let total_tracks = selected_tracks.length;
    for (var t = 0; t < total_tracks; t++) {
      let masterEstBySingle =
        prefix + internationalNumberFormat.format(Math.round(masterEst[t]));
      let publishESTbySingle = prefix + "0";
      trows[t] = {
        title: selected_tracks[t].title,
        masters_estimiate_revenue: masterEstBySingle,
        publishing_estimiate_revenue: publishESTbySingle,
        ownership: `${selected_tracks[t].stream_income_share}%`,
      };
    }

    const payload = {
      artist_name,
      shares_purchases,
      funding_estimiate_revenue,
      newmusic_estimiate_revenue,
      masters_estimiate_revenue,
      tracks: trows,
      total_tracks,
      length_of_contract,
      catalog_income_artist_keep,
      deal_type: "Existing Catalogue Only",
      created_at: new Date().toLocaleDateString(),
    };
    pdf.downloadPDF(payload);
  };

  async function updateArtistFunding() {
    if (artist?.spotify_id !== undefined) {
      const data = {
        missing_reports_compensation:
          funding_metadata?.missing_reports_compensation,
        catelog_income: funding_metadata?.catelog_income,
        dsp_rate: funding_metadata?.dsp_rate,
      };

      const response = await axios.put(
        `${URLconfig.BASE_URL}/artist-funding/${artist?.spotify_id}`,
        data
      );

      if (response.status === 200) {
        onChangeHandler();
      }

      // window.scroll({
      //   top: 0,
      //   left: 0,
      //   behavior: "smooth",
      // });
      toast.success("Saved successfully");
    }
  }

  const mapTracks = (track) => ({
    id: track._id,
    primary_artist: track?.artists[0]?.name,
    album: track?.album?.album,
    title: track.title,
    image: track.track_img,
    stream_income_share: track.stream_income_share,
    spotify_streams_total: track.spotify_streams_total,
    youtube_video_views_total: track.youtube_video_views_total,
    tiktok_views_total: track.tiktok_views_total,
    deezer_reach_total: track.deezer_reach_total,
    tiktok_shares_total: track.tiktok_shares_total,
    isrc: track.isrc,
    genres: track.genres,
    release_date: track.release_date,
    track_type: track.track_type,
    is_selected: track.is_selected,
    songstats_track_id: track.songstats_track_id,
    historic: track.historic,
    last_streams_growth: track.last_streams_growth,
    publish_stream_income_share: track.publish_stream_income_share,
    spotify_monthly_streams: track.spotify_monthly_streams,
    youtube_monthly_streams: track.youtube_monthly_streams,
  });

  const ShowContractContainer = () => {
    setShowContract(true);
    setShowDownloadBtn(true);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  // const TrackFeat = async () => {
  //   try {
  //     let options = {
  //       headers: {
  //         authorization: `Bearer ${getItemToLocalStorage(AuthEnum.TOKEN)}`,
  //       },
  //     };
  //     const response = await axios.get(
  //       `${URLconfig.BASE_URL}/artist-tracks/counter/featured-tracks/${artist?.name}`,
  //       options
  //     );
  //     setFeatureTracks(response?.data?.data[0]?.featureTracks);
  //     console.log("featureTracks", response);
  //   } catch (error) {
  //     console.error("Error fetching featured tracks:", error);
  //   }
  // };
  // useEffect(() => {
  //   TrackFeat();
  // }, []);
  // const status = useSelector((state) => state.artist.status);

  return (
    <Container maxWidth="xxl" className={classess.page}>
      <Grid container spacing={2}>
        <Grid item md={8} lg={8} xl={9} sm={12} xs={12}>
          <Box>
            <NewFundingDetails
              artist={artist}
              totalTracks={totalTracks}
              EstimatedYearlyEarnings={EstimatedYearlyEarnings}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container item spacing={2} flexWrap={"wrap-reverse"}>
        <Grid item md={8} lg={8} xl={9} sm={12} xs={12}>
          <Box sx={{ width: "100%", mt: 2 }}>
            <TabContext value={value}>
              <Box>
                <Tabs
                  onChange={handleChange}
                  centered
                  className={classess.page__tabs_container}
                >
                  <Tab
                    className={`${classess.page__tabs_container__tab} ${
                      value === 1
                        ? classess.page__tabs_container__tab__activeTabLinc
                        : ""
                    }`}
                    label="LICENSE"
                    value={1}
                    sx={{
                      color: value === 1 ? "#222c41" : "#5a7380",
                    }}
                    disabled={isLoading}
                  />
                  {user?.subscription === "bl-user" ? (
                    <Tab
                      className={`${classess.page__tabs_container__tab} ${
                        value === 2
                          ? classess.page__tabs_container__tab__activeTabBuy
                          : ""
                      }`}
                      label="BUYOUT"
                      value={2}
                      sx={{
                        color: value === 2 ? "#222c41" : "#5a7380",
                      }}
                      disabled={isLoading}
                    />
                  ) : (
                    <Tab
                      className={`${classess.page__tabs_container__tab} ${
                        value === 2
                          ? classess.page__tabs_container__tab__activeTabBuy
                          : ""
                      }`}
                      label="BUYOUT"
                      value={2}
                      sx={{
                        color: value === 2 ? "#222c41" : "#5a7380",
                        display: "flex",
                      }}
                      disabled={true}
                      icon={<RiLock2Line />}
                      iconPosition="start"
                    />
                  )}
                </Tabs>
              </Box>

              <TabPanel value={1} sx={{ p: 0 }}>
                <div>
                  <Lincense
                    isInputChanged={changeButton}
                    buttonStyle={buttonStyle}
                    ShowContractContainer={ShowContractContainer}
                    isLoading={isLoading}
                    setContainerExpanded={setContainerExpanded}
                    containerExpanded={containerExpanded}
                    Verify_component_here={
                      <VerifyConfig
                        handleOpen={handleOpen}
                        included_music={included_music}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                        new_music_income={new_music_income}
                        calcalute_tracks_estimate={calcalute_tracks_estimate}
                        calcalute_Year_income_by_tracks={
                          calcalute_Year_income_by_tracks
                        }
                        artist={artist}
                        showPublish={handleCheckboxChange}
                        showPublishValue={isChecked}
                        tracksLoad={tracksLoad}
                      />
                    }
                    NewMusic_component_here={
                      <ConfirmConfig
                        calcalute_tracks_estimate={calcalute_tracks_estimate}
                        included_music={included_music}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                        new_music_income={new_music_income}
                        set_new_music_income={set_new_music_income}
                        artist_id={artist?.spotify_id}
                      />
                    }
                    Customize_component_here={
                      <CustomizedConfig
                        set_included_music={set_included_music}
                        set_contract_length={set_contract_length}
                        set_catelog_income={set_catelog_income}
                        set_new_music_income={set_new_music_income}
                        included_music={included_music}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                        new_music_income={new_music_income}
                        funding_metadata={funding_metadata}
                        calcalute_customize_funding_estimate={
                          calcalute_customize_funding_estimate
                        }
                        calcalute_Year_income_by_tracks={
                          calcalute_Year_income_by_tracks
                        }
                        totalFunding={totalFunding}
                        calcalute_tracks_estimate={calcalute_tracks_estimate}
                        set_funding_metadata={set_funding_metadata}
                        updateArtistFunding={updateArtistFunding}
                        set_customize_funding={set_customize_funding}
                        artist_id={artist?.spotify_id}
                        new_music_estimiate={new_music_estimiate}
                        monthlyIncome={monthlyIncome}
                        set_artist_advance={set_artist_advance}
                        artist_advance={artist_advance}
                        set_marketing_budget={set_marketing_budget}
                        marketing_budget={marketing_budget}
                        internationalNumberFormat={internationalNumberFormat}
                        artAdvance={artAdvance}
                        setArtAdvance={setArtAdvance}
                        marketBudget={marketBudget}
                        setMarketBudget={setMarketBudget}
                        setRECOUPMENTPERIOD={setRECOUPMENTPERIOD}
                        setRECOUPMENTPERIOD_IN_ENG={setRECOUPMENTPERIOD_IN_ENG}
                        setChangeButton={setChangeButton}
                        tabValue={value}
                      />
                    }
                    Uplaod_component_here={
                      <SendConfig
                        totalFunding={totalFunding}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                      />
                    }
                  />
                </div>
              </TabPanel>

              <TabPanel value={2} sx={{ p: 0 }}>
                <div>
                  <BuyOut
                    isLoading={isLoading}
                    Verify_component_here={
                      <VerifyConfig
                        handleOpen={handleOpen}
                        included_music={included_music}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                        new_music_income={new_music_income}
                        calcalute_tracks_estimate={calcalute_tracks_estimate}
                        calcalute_Year_income_by_tracks={
                          calcalute_Year_income_by_tracks
                        }
                        artist={artist}
                        showPublish={handleCheckboxChange}
                        showPublishValue={isChecked}
                      />
                    }
                    Customize_component_here={
                      <CustomizedConfig
                        set_included_music={set_included_music}
                        set_contract_length={set_contract_length}
                        set_catelog_income={set_catelog_income}
                        set_new_music_income={set_new_music_income}
                        included_music={included_music}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                        new_music_income={new_music_income}
                        funding_metadata={funding_metadata}
                        calcalute_customize_funding_estimate={
                          calcalute_customize_funding_estimate
                        }
                        totalFunding={totalFunding}
                        calcalute_tracks_estimate={calcalute_tracks_estimate}
                        set_funding_metadata={set_funding_metadata}
                        updateArtistFunding={updateArtistFunding}
                        set_customize_funding={set_customize_funding}
                        artist_id={artist?.spotify_id}
                        new_music_estimiate={new_music_estimiate}
                        monthlyIncome={monthlyIncome}
                        set_artist_advance={set_artist_advance}
                        artist_advance={artist_advance}
                        set_marketing_budget={set_marketing_budget}
                        marketing_budget={marketing_budget}
                        internationalNumberFormat={internationalNumberFormat}
                        artAdvance={artAdvance}
                        setArtAdvance={setArtAdvance}
                        marketBudget={marketBudget}
                        setMarketBudget={setMarketBudget}
                        setRECOUPMENTPERIOD={setRECOUPMENTPERIOD}
                        setRECOUPMENTPERIOD_IN_ENG={setRECOUPMENTPERIOD_IN_ENG}
                        setChangeButton={setChangeButton}
                        tabValue={value}
                      />
                    }
                    Uplaod_component_here={
                      <SendConfig
                        totalFunding={totalFunding}
                        contract_length={contract_length}
                        catelog_income={catelog_income}
                      />
                    }
                  />
                </div>
              </TabPanel>
            </TabContext>
          </Box>

          <Box mt={2}>
            {showContract && value === 1 && (
              <InitiateContract
                contract_length={contract_length}
                artist_advance={artist_advance}
                marketing_budget={marketing_budget}
                totalFunding={totalFunding}
                spotifyId={artist?.spotify_id}
              />
            )}
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          lg={4}
          xl={3}
          sm={12}
          xs={12}
          className={classess.page__dealSummary}
        >
          <DealSummary
            totalFunding={totalFunding}
            contract_length={contract_length}
            publishEst={publishEst}
            totalTracks={totalTracks}
            artist_advance={artist_advance}
            marketing_budget={marketing_budget}
            RECOUPMENTPERIOD={RECOUPMENTPERIOD}
            RECOUPMENTPERIOD_IN_ENG={RECOUPMENTPERIOD_IN_ENG}
            isLoading={isLoading}
            showPublishValue={isChecked}
            isPending={pdf.isPending}
            downloadPDF={downloadPDF}
            showDownloadBtn={showDownloadBtn}
            new_music_estimiate={new_music_estimiate}
            SingleCount={SingleCount}
            EpCount={EpCount}
            AlbumCount={AlbumCount}
            catelog_income={catelog_income}
            CountryWiseEst={CountryWiseEst}
            tabValue={value}
          />
        </Grid>
      </Grid>
      <AddTracks
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        spotifyId={artist?.spotify_id}
      />
      {modalName === "edit-track" && <EditTrackModel />}
      {modalName === "track-chart" && <TrackChart />}
    </Container>
  );
};

export default ViewFundingDashboard;
