/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import classess from "./style.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { addCommasToNumber } from "../../../utils/helper";
import moment from "moment";
import { useSelector } from "react-redux";
import NewLoader from "../../lottie-loader/Loader.json";
import Lottie from "lottie-react";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { config as URLconfig } from "../../../enviorment/enviorment";
import axios from "axios";
import { getItemToLocalStorage } from "../../../services/storage";
import { toast } from "react-toastify";
import { RiLock2Line } from "react-icons/ri";

const NewFundingDetails = ({
  artist,
  totalTracks,
  EstimatedYearlyEarnings,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const storedToken = getItemToLocalStorage("accessToken");
  const status = useSelector((state) => state.artist.status);
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const [queue, setQueue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const tracks = useSelector((state) => state.artist.tracks);
  const reports = useSelector((state) => state.artist.reports);
  const [updateMoment, setUpdateMoment] = useState("");
  const user = useSelector((state) => state.auth.user);

  // console.log("reports", reports);
  const isLoadingEstEarning = useSelector(
    (state) => state.artist.isLoadingEstEarning
  );
  const UpdatesTrackInfo = () => {
    // toast.info("Updating Tracks");
    const payload = {
      spotify_id: artist?.spotify_id,
    };

    setIsLoading(true);
    axios
      .post(`${URLconfig.BASE_URL}/artists/artistupdate/`, payload, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((res) => {
        setQueue(null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        toast.success("Updating Tracks Successfully");
        setUpdateMoment(moment().format("MMM DD YY [at] hh:mm")); // Update last updated time
      });
  };

  const regex = new RegExp(
    `\\b(?:feature|feat(?:\\.|uring)?|ft(?:\\.)?|with)\\b.*\\b${artist?.name}\\b`,
    "i"
  );
  const sumData = {};
  reports?.forEach((report) => {
    if (report?.insights) {
      Object.entries(report.insights)?.forEach(([year, data]) => {
        if (data?.sum) {
          sumData[year] = data.sum;
        }
      });
    }
  });

  let totalSum = 0;
  // Check if sumData is not null or undefined
  if (sumData) {
    Object.values(sumData)?.forEach((sum) => {
      // Check if sum is not null or undefined
      if (sum) {
        totalSum += sum;
      }
    });
  }
  const data = [
    {
      heading: "LISTENERSHIP",
      value:
        status === "succeeded" ? (
          addCommasToNumber(
            artist?.chartmetric?.cm_statistics?.sp_monthly_listeners
          )
        ) : (
          <Loader />
        ),
    },
    {
      heading: "Featured Tracks",
      value:
        status === "succeeded" ? (
          tracks.filter((track) => regex.test(track.title)).length
        ) : (
          <Loader />
        ),
      // paddingRight: "15",
      paddingLeft: "15",
    },
    {
      heading: "TOTAL TRACKS",
      value: status === "succeeded" ? totalTracks : <Loader />,
      paddingLeft: "15",
    },

    {
      heading: "ESTIMATED YEARLY EARNINGS",
      value: "$" + internationalNumberFormat.format(EstimatedYearlyEarnings),
      isLoading: isLoadingEstEarning,
      paddingLeft: "15",
    },
    {
      heading: "STATEMENT Earnings",
      value:
        status === "succeeded" ? (
          reports.length > 0 ? (
            <>${internationalNumberFormat.format(Math.round(totalSum))}</>
          ) : (
            <span>$0</span>
          )
        ) : (
          <Loader />
        ),
      borderRight: "none",
      paddingLeft: "15",
    },
  ];

  // console.log("sumData:", sumData);
  // console.log("Total sum of all years:", totalSum);
  return (
    <Box className={classess.page}>
      <Box className={classess.page__container}>
        <Box>
          <Avatar
            className={classess.page__container__img}
            src={artist?.avatar}
            alt={artist?.name}
            variant="square"
          />
        </Box>
        <Box className={classess.page__container__details}>
          <Box className={classess.page__container__details__top}>
            <Box className={classess.page__container__details__top__name}>
              <Typography
                className={
                  classess.page__container__details__top__name__artist_name
                }
                onClick={() => navigate(`/blig/view-artist/${id}`)}
              >
                {artist?.name}
              </Typography>
            </Box>
            <Box>
              {user?.subscription === "bl-user" ? (
                <Button
                  className={classess.page__container__details__top__update_btn}
                  // startIcon={<SyncRoundedIcon />}
                  startIcon={
                    <SyncRoundedIcon
                      className={
                        isLoading
                          ? `${classess.page__container__details__top__update_btn__rotate}`
                          : ""
                      }
                    />
                  }
                  onClick={UpdatesTrackInfo}
                >
                  Update Track Stats
                </Button>
              ) : (
                <Tooltip
                  title={"Unlock Pro Features"}
                  placement="top"
                  arrow
                  enterDelay={100}
                >
                  <Button
                    className={
                      classess.page__container__details__top__update_btn
                    }
                    sx={{
                      opacity: 0.8,
                    }}
                    startIcon={<RiLock2Line />}
                  >
                    Update Track Stats
                  </Button>
                </Tooltip>
              )}
              <Box
                className={
                  classess.page__container__details__bottom__box__detail__subPara
                }
              >
                Last updated on{" "}
                {updateMoment ? (
                  updateMoment
                ) : (
                  <>
                    {moment(artist?.updatedAt).format("MMM DD YY")} at{" "}
                    {moment(artist?.updatedAt).format("hh:mm")}
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classess.page__container__details__bottom}>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <DetailsBox
                  heading={item.heading}
                  value={item.value}
                  subPara={item.subPara}
                  isLoading={item.isLoading}
                  borderRight={item.borderRight}
                  padding={item.paddingLeft}
                />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Loader = () => {
  return (
    <Box>
      <Lottie animationData={NewLoader} className={classess.page__loader} />
    </Box>
  );
};

const DetailsBox = ({
  heading,
  value,
  padding,
  borderRight,
  subPara,
  isLoading,
}) => {
  return (
    <Box
      className={classess.page__container__details__bottom__box}
      style={{
        borderRight: borderRight,
      }}
    >
      <Box
        className={classess.page__container__details__bottom__box__detail}
        style={{
          paddingLeft: `${padding}px`,
        }}
      >
        <Typography
          className={
            classess.page__container__details__bottom__box__detail__heading
          }
        >
          {heading} :
        </Typography>
        <Typography
          className={
            classess.page__container__details__bottom__box__detail__value
          }
        >
          {isLoading ? <Loader /> : value}
        </Typography>
        {subPara && (
          <Typography
            className={
              classess.page__container__details__bottom__box__detail__subPara
            }
          >
            {subPara}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NewFundingDetails;
