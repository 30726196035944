// 1. Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Avatar,
  Button,
  IconButton,
  CircularProgress,
  Grid,
} from "@mui/material";
import PhoneInput from "react-phone-number-input";
import editIcon from "../../assets/icons/edit.svg";
import {
  CustomSelect,
  CustomTextField,
  CustomMenuItem,
} from "../../custom-mui-style/custom-mui-styles";
import classess from "./style.module.scss";
import { updateUser, updateUserWithProfile, me } from "../../redux/slice/auth";
import { getCountries } from "../../redux/slice/country";
import { scrollTopObserver } from "../../utils/helper";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const allCountries = useSelector((state) => state.country.countries);
  const [avatar, setAvatar] = useState();
  const [image, setImage] = useState();
  const [profileImage, setProfileImage] = useState();
  const [isProfileChanged, setIsProfileChanged] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState();
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const resetUser = () => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setUsername(user?.username);
    setPhone(user?.phone);
    setCompany(user?.company);
    setCountry(user?.country);
    setWebsite(user?.website);
    setImage(user?.profilePicture);
  };

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setEmail(user?.email);
    setUsername(user?.username);
    setPhone(user?.phone);
    setCompany(user?.company);
    setCountry(user?.country);
    setWebsite(user?.website);
    setImage(user?.profilePicture);
  }, []);

  const goBack = () => {
    navigate("/blig/home");
    scrollTopObserver();
  };

  const handleProfileChange = (event) => {
    setAvatar(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
    setProfileImage(URL.createObjectURL(event.target.files[0]));
    setIsProfileChanged(true);
    event.target.files = null;
  };

  const responsoSuccess = () => {
    dispatch(me());
    setIsLoading(false);
    resetUser();
    goBack();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleOpenSelect = () => setIsOpen(true);
  const handleCloseSelect = () => setIsOpen(false);

  // useEffect(() => {
  //   if (user) {
  //     resetUser();
  //   }
  // }, [user]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const onSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (!isProfileChanged) {
      try {
        const payload = {
          firstName,
          lastName,
          email,
          username,
          phone,
          company,
          country,
          website,
        };
        const response = dispatch(
          updateUser({
            id: user?.id,
            data: payload,
          })
        );

        response.then(() => {
          responsoSuccess();
        });
      } catch (err) {
        console.error("Failed to update user:", err);
        setIsLoading(false);
      }
    } else {
      try {
        let payload = new FormData();
        payload.append("file", avatar);
        payload.append("firstName", firstName);
        payload.append("lastName", lastName);
        payload.append("email", email);
        payload.append("username", username);
        payload.append("phone", phone);
        payload.append("company", company);
        payload.append("country", country);
        payload.append("website", website);

        const responseImg = dispatch(
          updateUserWithProfile({
            id: user?.id,
            data: payload,
          })
        );

        responseImg.then(() => {
          responsoSuccess();
        });
      } catch (err) {
        console.error("Failed to update user with profile image:", err);
        resetUser();
        setIsLoading(false);
      }
    }
  };

  // const customScrollbarStyles = {
  //   overflow: "auto",
  //   maxHeight: 300,
  //   "&::-webkit-scrollbar": {
  //     width: "8px",
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     backgroundColor: "transparent",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     backgroundColor: "#00135a",
  //     borderRadius: "4px",
  //   },
  // };

  return (
    <Grid container pl={2.5}>
      <Grid item sm={12} lg={9} xl={9}>
        <Box varient="div" component="div" className={classess.page}>
          <span className={classess.page__heading}>User Profile</span>
          <Box varient="div" component="div" className={classess.page__box}>
            <UserProfileImage
              profileImage={profileImage}
              image={image}
              user={user}
              handleProfileChange={handleProfileChange}
            />

            <form
              autoComplete="off"
              onSubmit={onSubmit}
              className={classess.page__box__form}
            >
              <Box className={classess.page__box__form__field_container}>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    First Name *
                  </label>
                  <CustomTextField
                    value={firstName}
                    defaultValue={user.firstName}
                    type="text"
                    required
                    onInput={(event) => setFirstName(event.target.value)}
                    maxLength={16}
                  />
                </Box>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Last Name *
                  </label>
                  <CustomTextField
                    value={lastName}
                    defaultValue={user.lastName}
                    type="text"
                    required
                    onInput={(event) => setLastName(event.target.value)}
                    maxLength={16}
                  />
                </Box>
              </Box>
              <Box className={classess.page__box__form__field_container}>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Email *
                  </label>
                  <CustomTextField
                    value={email}
                    defaultValue={user.email}
                    type="text"
                    required
                    // onInput={(event) => setEmail(event.target.value)}

                    // readOnly
                  />
                </Box>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Username *
                  </label>
                  <CustomTextField
                    value={username}
                    defaultValue={user.username}
                    type="text"
                    required
                    // onInput={(event) => setUsername(event.target.value)}

                    // readOnly
                  />
                </Box>
              </Box>

              <Box className={classess.page__box__form__field_container}>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Country
                  </label>

                  <CustomSelect
                    value={country}
                    onChange={(event) => setCountry(event.target.value)}
                    // required
                    displayEmpty
                    onOpen={handleOpenSelect}
                    onClose={handleCloseSelect}
                    IconComponent={() =>
                      isOpen ? (
                        <IoIosArrowUp
                          className={
                            classess.page__box__form__field_container__field__select_icon
                          }
                        />
                      ) : (
                        <IoIosArrowDown
                          className={
                            classess.page__box__form__field_container__field__select_icon
                          }
                        />
                      )
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    <CustomMenuItem value="">Select Country</CustomMenuItem>
                    {allCountries && allCountries.length > 0
                      ? allCountries.map((item) => (
                          <CustomMenuItem key={item.value} value={item.value}>
                            {item.label}
                          </CustomMenuItem>
                        ))
                      : null}
                  </CustomSelect>
                </Box>

                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Phone *
                  </label>

                  <PhoneInput
                    international
                    className={
                      classess.page__box__form__field_container__field__phoneinput
                    }
                    defaultCountry={country}
                    placeholder="Enter phone number"
                    value={phone}
                    name="phone"
                    onChange={setPhone}
                    required
                    autoComplete="off"
                    countryCallingCodeEditable={false}
                    style={{
                      border: isFocused
                        ? "1px solid var(--base-input-focus-color)"
                        : "1px solid var(--base-input-border-color)",
                      transition: "all 0.3s ease-in-out",
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </Box>
              </Box>
              <Box className={classess.page__box__form__field_container}>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Company
                  </label>
                  <CustomTextField
                    placeholder="Black Lion"
                    value={company}
                    type="text"
                    onInput={(event) => setCompany(event.target.value)}
                    maxLength={30}
                  />
                </Box>
                <Box
                  varient="div"
                  component="div"
                  className={classess.page__box__form__field_container__field}
                >
                  <label
                    className={
                      classess.page__box__form__field_container__field__label
                    }
                  >
                    Website
                  </label>
                  <CustomTextField
                    value={website}
                    defaultValue={user.website}
                    type="text"
                    placeholder="www.example.com"
                    onInput={(event) => setWebsite(event.target.value)}
                  />
                </Box>
              </Box>

              <FormActions isLoading={isLoading} goBack={goBack} />
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

// User Image Component
const UserProfileImage = ({
  profileImage,
  image,
  user,
  handleProfileChange,
}) => (
  <Box varient="div" component="div" className={classess.page__box__image}>
    <Box varient="div" component="div" className={classess.page__box__image}>
      <Avatar
        src={profileImage ? image : image}
        sx={{
          height: 89,
          width: 89,
          border: "5px solid var(--base-img-border-color)",
        }}
      />
      <IconButton
        className={classess.page__box__image__btn}
        color="primary"
        aria-label="upload picture"
        component="label"
      >
        <input
          hidden
          accept="image/*"
          type="file"
          onChange={handleProfileChange}
        />
        <img src={editIcon} alt="edit Icon" />
      </IconButton>
      <span className={classess.page__box__image__title}>
        {user?.firstName + " " + user?.lastName}
      </span>
    </Box>
  </Box>
);

const FormActions = ({ isLoading, goBack }) => (
  <Box
    varient="div"
    component="div"
    className={classess.page__box__form__actions}
  >
    <Stack
      direction="row"
      gap={3}
      sx={{ width: { xs: "100%", sm: "100%", lg: "45%" } }}
    >
      <Button
        variant="contained"
        type="button"
        className={classess.page__box__form__actions__cancel_btn}
        disabled={isLoading}
        onClick={goBack}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        type="submit"
        className={classess.page__box__form__actions__submit_btn}
        disabled={isLoading}
      >
        {isLoading && <CircularProgress size={25} color="inherit" />}
        Submit
      </Button>
    </Stack>
  </Box>
);

export default ProfilePage;
