import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../redux/slice/auth";
import { scrollTopObserver } from "../../utils/helper";
import classess from "./style.module.scss";
import HeaderMenu from "../header-menu/header-menu";
import DashboardIcon from "../../assets/drawer/DashboardIcon.svg";
import ArtistIcon from "../../assets/drawer/ArtistIcon.svg";
import ContractIcon from "../../assets/drawer/ContractIcon.svg";
import PaymentsIcon from "../../assets/drawer/PaymentIcon.svg";
import { ReactSVG } from "react-svg";
import { RiLock2Line } from "react-icons/ri";
import { Tooltip } from "@mui/material";

const DrawerItems = ({ handleClose }) => {
  // const [isDark, setIsDark] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();

  const config = {
    primaryMenu: [
      {
        name: "Dashboard",
        path: "/blig/home",
        icon: <ReactSVG src={DashboardIcon} className={classess.drawer_icon} />,
      },
      {
        name: "Artists",
        path: "/blig/my-artist",
        path2: "/blig/view-artist/:id",
        path3: "/blig/view-funding-dashboard/:id",
        path4: "/blig/edit-dashboard/:id",
        path5: "/blig/track/:id",
        icon: <ReactSVG src={ArtistIcon} className={classess.drawer_icon} />,
      },
      {
        name: "Contracts",
        path: "/blig/contracts",
        path2: "/blig/contracts/:id",
        icon: <ReactSVG src={ContractIcon} className={classess.drawer_icon} />,
      },
      {
        name: "Payments",
        path: "/blig/payments",
        path2: "/blig/payment/:id",
        icon: <ReactSVG src={PaymentsIcon} className={classess.drawer_icon} />,
      },
      // {
      //   name: "Notifications",
      //   path: "/blig/notifications",
      //   icon: (
      //     <ReactSVG src={NotificationIcon} className={classess.drawer_icon} />
      //   ),
      // },
    ],

    userMenu: [
      {
        name: "Artists",
        path2: "/blig/my-artist",
        path3: "/blig/view-artist/:id",
        path4: "/blig/view-funding-dashboard/:id",
        path5: "/blig/edit-dashboard/:id",
        path6: "/blig/track/:id",
        path: "/blig/home",
        icon: <ReactSVG src={ArtistIcon} className={classess.drawer_icon} />,
      },
      {
        name: "Contracts",
        path: "/blig/contracts",
        path2: "/blig/contracts/:id",
        icon: <ReactSVG src={ContractIcon} className={classess.drawer_icon} />,
      },

      // {
      //   name: "Payments",
      //   path: "/blig/payments",
      //   path2: "/blig/payment/:id",
      //   icon: <ReactSVG src={PaymentsIcon} className={classess.drawer_icon} />,
      // },
      ...(user?.subscription === "bl-user"
        ? [
            {
              name: "Payments",
              path: "/blig/payments",
              path2: "/blig/payment/:id",
              icon: (
                <ReactSVG src={PaymentsIcon} className={classess.drawer_icon} />
              ),
            },
          ]
        : [
            {
              name: "Payments",
              toolTip: "Unlock Pro Features",
              icon: <RiLock2Line className={classess.drawer_LockIcon} />,
            },
          ]),
      // {
      //   name: "Notifications",
      //   path: "/blig/notifications",
      //   icon: (
      //     <ReactSVG src={NotificationIcon} className={classess.drawer_icon} />
      //   ),
      // },
    ],

    artistMenu: [
      {
        name: "Deals",
        path: "/blig/artist-deals",
        icon: <ReactSVG src={DashboardIcon} className={classess.drawer_icon} />,
      },

      {
        name: "Payments",
        path: "/blig/artist-payments",
        icon: <ReactSVG src={ContractIcon} className={classess.drawer_icon} />,
      },

      {
        name: "Team",
        path: "/blig/artist-team",
        icon: <ReactSVG src={ArtistIcon} className={classess.drawer_icon} />,
      },

      {
        name: "My Account",
        path: "/blig/artist-my-account",
        icon: <ReactSVG src={PaymentsIcon} className={classess.drawer_icon} />,
      },

      // {
      //   name: "Notification",
      //   path: "/blig/artist-notification",
      //   icon: (
      //     <ReactSVG src={NotificationIcon} className={classess.drawer_icon} />
      //   ),
      // },
    ],

    financeMenu: [
      {
        name: "Deals",
        path: "/blig/home",
        icon: <ReactSVG src={ContractIcon} className={classess.drawer_icon} />,
      },

      {
        name: "Analytics",
        path: "/blig/analytics",
        icon: <ReactSVG src={ArtistIcon} className={classess.drawer_icon} />,
      },

      {
        name: "Integrations",
        path: "/blig/integrations",
        icon: <ReactSVG src={PaymentsIcon} className={classess.drawer_icon} />,
      },

      // {
      //   name: "Notification",
      //   path: "/blig/notification",
      //   icon: (
      //     <ReactSVG src={NotificationIcon} className={classess.drawer_icon} />
      //   ),
      // },
    ],
  };

  return (
    user && (
      <Box
        variant="div"
        component="div"
        className={classess.page}
        // data-theme={isDark ? "dark" : "light"}
      >
        <Box className={classess.page__topHead}>
          <Box className={classess.page__topHead__img_logo}></Box>
          {/* <img src={logo} height="60" alt="logo" /> */}
        </Box>

        <List className={classess.page__mainMenu}>
          {user.role === "admin" && (
            <React.Fragment>
              {config.primaryMenu.map((menu, index) => {
                // Check if the current route is active
                const isPathActive =
                  location.pathname === menu.path ||
                  (menu.path2 &&
                    location.pathname.startsWith(
                      menu.path2.replace(":id", "")
                    )) ||
                  (menu.path3 &&
                    location.pathname.startsWith(
                      menu.path3.replace(":id", "")
                    )) ||
                  (menu.path4 &&
                    location.pathname.startsWith(
                      menu.path4.replace(":id", "")
                    )) ||
                  (menu.path5 &&
                    location.pathname.startsWith(
                      menu.path5.replace(":id", "")
                    ));

                return (
                  <ListItem
                    key={menu.name}
                    className={classess.page__mainMenu__list}
                  >
                    <ListItemButton
                      component={Link}
                      to={menu.path}
                      className={classess.page__mainMenu__button}
                      // Handle navigation and other actions
                      onClick={() => {
                        handleClose();
                        scrollTopObserver();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon
                        className={classess.page__mainMenu__button__icon}
                      >
                        {/* Render the active or inactive icon */}
                        {isPathActive ? (
                          <div className={classess.active_icon}>
                            {menu.icon}
                          </div>
                        ) : (
                          <div className={classess.inactive_icon}>
                            {menu.icon}
                          </div>
                        )}
                      </ListItemIcon>

                      {/* Render the menu item text */}
                      <ListItemText
                        primary={menu.name}
                        className={
                          isPathActive
                            ? classess.page__mainMenu__button__text_border
                            : classess.page__mainMenu__button__text
                        }
                        primaryTypographyProps={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </React.Fragment>
          )}

          {user.role === "user" && (
            <React.Fragment>
              {config.userMenu.map((menu, index) => {
                // Check if the current route is active
                const isPathActive =
                  location.pathname === menu.path ||
                  (menu.path2 &&
                    location.pathname.startsWith(
                      menu.path2.replace(":id", "")
                    )) ||
                  (menu.path3 &&
                    location.pathname.startsWith(
                      menu.path3.replace(":id", "")
                    )) ||
                  (menu.path4 &&
                    location.pathname.startsWith(
                      menu.path4.replace(":id", "")
                    )) ||
                  (menu.path5 &&
                    location.pathname.startsWith(
                      menu.path5.replace(":id", "")
                    )) ||
                  (menu.path6 &&
                    location.pathname.startsWith(
                      menu.path6.replace(":id", "")
                    ));

                return (
                  <ListItem
                    key={menu.name}
                    className={classess.page__mainMenu__list}
                  >
                    <ListItemButton
                      component={Link}
                      to={menu.path}
                      className={classess.page__mainMenu__button}
                      // Handle navigation and other actions
                      onClick={() => {
                        handleClose();
                        scrollTopObserver();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon
                        className={classess.page__mainMenu__button__icon}
                      >
                        {/* Render the active or inactive icon */}
                        {isPathActive ? (
                          <div className={classess.active_icon}>
                            {menu.icon}
                          </div>
                        ) : (
                          <div className={classess.inactive_icon}>
                            {menu.icon}
                          </div>
                        )}
                      </ListItemIcon>

                      {/* Render the menu item text */}
                      <Tooltip
                        title={menu.toolTip}
                        placement="top"
                        arrow
                        enterDelay={100}
                      >
                        <ListItemText
                          primary={menu.name}
                          className={
                            isPathActive
                              ? classess.page__mainMenu__button__text_border
                              : classess.page__mainMenu__button__text
                          }
                          primaryTypographyProps={{
                            fontSize: "14px",
                            fontWeight: "500",
                            fontFamily: "DM Sans",
                          }}
                        />
                      </Tooltip>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </React.Fragment>
          )}

          {user.role === "artist" && (
            <React.Fragment>
              {config.artistMenu.map((menu, index) => {
                // Check if the current route is active
                const isPathActive =
                  location.pathname === menu.path ||
                  (menu.path2 &&
                    location.pathname.startsWith(
                      menu.path2.replace(":id", "")
                    )) ||
                  (menu.path3 &&
                    location.pathname.startsWith(
                      menu.path3.replace(":id", "")
                    )) ||
                  (menu.path4 &&
                    location.pathname.startsWith(
                      menu.path4.replace(":id", "")
                    )) ||
                  (menu.path5 &&
                    location.pathname.startsWith(
                      menu.path5.replace(":id", "")
                    ));

                return (
                  <ListItem
                    key={menu.name}
                    className={classess.page__mainMenu__list}
                  >
                    <ListItemButton
                      component={Link}
                      to={menu.path}
                      className={classess.page__mainMenu__button}
                      // Handle navigation and other actions
                      onClick={() => {
                        handleClose();
                        scrollTopObserver();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon
                        className={classess.page__mainMenu__button__icon}
                      >
                        {/* Render the active or inactive icon */}
                        {isPathActive ? (
                          <div className={classess.active_icon}>
                            {menu.icon}
                          </div>
                        ) : (
                          <div className={classess.inactive_icon}>
                            {menu.icon}
                          </div>
                        )}
                      </ListItemIcon>

                      {/* Render the menu item text */}
                      <ListItemText
                        primary={menu.name}
                        className={
                          isPathActive
                            ? classess.page__mainMenu__button__text_border
                            : classess.page__mainMenu__button__text
                        }
                        primaryTypographyProps={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </React.Fragment>
          )}

          {user.role === "finance" && (
            <React.Fragment>
              {config.financeMenu.map((menu, index) => {
                // Check if the current route is active
                const isPathActive =
                  location.pathname === menu.path ||
                  (menu.path2 &&
                    location.pathname.startsWith(
                      menu.path2.replace(":id", "")
                    )) ||
                  (menu.path3 &&
                    location.pathname.startsWith(
                      menu.path3.replace(":id", "")
                    )) ||
                  (menu.path4 &&
                    location.pathname.startsWith(
                      menu.path4.replace(":id", "")
                    )) ||
                  (menu.path5 &&
                    location.pathname.startsWith(
                      menu.path5.replace(":id", "")
                    ));

                return (
                  <ListItem
                    key={menu.name}
                    className={classess.page__mainMenu__list}
                  >
                    <ListItemButton
                      component={Link}
                      to={menu.path}
                      className={classess.page__mainMenu__button}
                      // Handle navigation and other actions
                      onClick={() => {
                        handleClose();
                        scrollTopObserver();
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ListItemIcon
                        className={classess.page__mainMenu__button__icon}
                      >
                        {/* Render the active or inactive icon */}
                        {isPathActive ? (
                          <div className={classess.active_icon}>
                            {menu.icon}
                          </div>
                        ) : (
                          <div className={classess.inactive_icon}>
                            {menu.icon}
                          </div>
                        )}
                      </ListItemIcon>

                      {/* Render the menu item text */}
                      <ListItemText
                        primary={menu.name}
                        className={
                          isPathActive
                            ? classess.page__mainMenu__button__text_border
                            : classess.page__mainMenu__button__text
                        }
                        primaryTypographyProps={{
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </React.Fragment>
          )}
        </List>

        {/* <ToggleButtonForTheme
          isChecked={isDark}
          handleChange={() => setIsDark(!isDark)}
        /> */}

        {/* <Box>
          <label>
            <input
              type="radio"
              checked={theme === "light"}
              onChange={() => toggleTheme()}
            />{" "}
            Light
          </label>
          <label>
            <input
              type="radio"
              checked={theme === "dark"}
              onChange={() => toggleTheme()}
            />{" "}
            Dark
          </label>
        </Box> */}

        <Box
          variant="div"
          component="div"
          className={classess.page__bottom_container}
        >
          <HeaderMenu />

          <Box
            className={classess.page__bottom_container__logOutbtn}
            onClick={() => {
              dispatch(logout());
              navigation("/login");
            }}
          >
            <LogoutIcon />
            <span
              className={
                classess.page__bottom_container__logOutbtn__button_text
              }
            >
              Logout
            </span>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default DrawerItems;
